<template>
  <div class="resume">
    <div class="resume-height">AI简历大赛</div>
    <div class="resume-main">
      <div class="main-data" v-for="item in resumeData" :key="item.shop_user_ai_resume_id" @click="gotoResume(item.shop_user_ai_resume_id)">
        <div class="data-height">
          <el-image class="head-image" :src="require(`../../../../assets/image/resume.png`)" fit="contain"></el-image>
        </div>
        <div class="data-bottom">
          <div class="bottom-title">
            <div class="title-name">{{ item.name === null ? '未实名' : item.name }}</div>
            <div class="title-score">{{ item.score === null ? 0 : item.score }}分</div>
          </div>
          <div class="subhead">班级：{{ item.college === null ? '未实名' : item.college }}</div>
          <div class="subhead">学号：{{ item.number === null ? '未实名' : item.number }}</div>
        </div>
      </div>
    </div>
    <div class="resume-bottom">
      <el-button type="warning" size="mini" @click="submitForm">一键发布</el-button>
    </div>
    <el-image class="logo-image" :src="require(`../../../../assets/image/logomini.png`)"></el-image>
  </div>
</template>

<script>
import { getAdminResume, submitResume } from '../../../../request/api'

export default {
  data() {
    return {
      resumeData: []
    }
  },
  methods: {
    // 一键发布
    submitForm() {
      this.$confirm('发布后学生可以查看成绩！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        try {
          const res = await submitResume()
          if (res.status !== 200 || res.data.code !== "1000") {
            return this.$message.error(res.data.msg);
          }

          this.$message.success('发布成功')
        } catch(error) {
          this.$message.error('请求发生错误：' + error.message);
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async getResumeList() {
      try {
        const res = await getAdminResume()
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.resumeData = res.data.data.map(ele => {
          if (ele.score === null) return ele

          let numbers = ele.score.split(',')
          ele.score = numbers.reduce((accumulator, currentValue) => {
            return accumulator + parseInt(currentValue, 10)
          }, 0)
          return ele
        })
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message);
      }
    },
    gotoResume(shop_user_ai_resume_id) {
      this.$router.push({
        path: '/aiCompetition/details',
        query: {
          shop_user_ai_resume_id: shop_user_ai_resume_id
        }
      })
    }
  },
  mounted() {
    this.getResumeList()
  }
}
</script>

<style lang="less" scoped>
.resume {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .resume-height {
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #5699fe;
    background-color: #fff;
  }
  .resume-main {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .main-data {
      margin: 20px;
      background-color: #fefefe;
      height: 340px;
      width: 260px;
      box-shadow: 0 0 10px 2px rgba(1, 22, 80, 0.1);
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .data-height {
        height: 70%;
        background-color: #e9f0fa;
        display: flex;
        align-items: end;
        justify-content: center;
        .head-image {
          height: 90%;
        }
      }
      .data-bottom {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .bottom-title {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          height: 40%;
          justify-content: center;
          align-items: center;
          .title-score {
            color: #f29c14;
          }
          .title-name {
            margin-right: 20px;
          }
        }
        .subhead {
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
    }
  }
  .resume-bottom {
    z-index: 2;
    height: 60px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
  .logo-image {
    pointer-events: none;
    position: absolute;
    width: 50%;
    bottom: 0px;
    right: 0px;
    opacity: 0.2;
    z-index: 1;
  }
}
</style>